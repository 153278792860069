<template>
  <AppCard class="full-height-iframes">
    <AppSpinnerBig :loading="isIframeLoading" />
    <iframe :src="iframeSrc" allow="geolocation" @load="onIframeLoaded"></iframe>
  </AppCard>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useIframeLoader } from '../composables/useIframeLoader'
import { useDashboardView } from '@galileo/composables/useDashboardView'

import { AppSpinnerBig, AppCard } from '@oen.web.vue2/ui'

import { useEnvStore } from '@galileo/stores'

export default {
  name: 'DocumentUpload',
  components: {
    AppSpinnerBig,
    AppCard,
  },
  setup() {
    const { isIframeLoading, onIframeLoaded } = useIframeLoader()
    const { mounted, unmounted } = useDashboardView()
    const envStore = useEnvStore()
    const iframeSrc = computed(() => envStore.appUrlTransfer + 'account/auth/upload')
    //const iframeSrc = 'https://transfer.xe.com.uat.poc/account/auth/upload'
    mounted()
    unmounted()

    return {
      isIframeLoading,
      onIframeLoaded,
      iframeSrc,
    }
  },
}
</script>

<style scoped>
.full-height-iframe {
  height: 100vh !important;
}
.locations {
  @apply h-full w-full;

  iframe {
    @apply absolute w-full;
    @apply z-40;
    margin-top: -80px;
    height: calc(100% + 79px - 56px);

    @screen md {
      width: calc(100% - 256px);
      height: calc(100% + 79px);
    }
  }
}

.card {
  @apply mt-12 mb-12 mx-auto;
  @apply max-w-3xl;
  @apply max-h-10/12;

  ::v-deep .card-content {
    @apply pb-12;
    .card-content-block {
      @apply h-full;

      iframe {
        @apply h-full w-full;
        @apply z-40;
      }
    }
  }
}
</style>
